import { FC } from 'react';
import { Titillium_Web } from 'next/font/google';
import Head from 'next/head';

export const titillium = Titillium_Web({
    weight: ['400', '600', '700'],
    style: ['normal', 'italic'],
    subsets: ['latin'],
    display: 'swap',
});

export const Titillium: FC<{}> = () => (
    <Head>
        <style
            key="fontfaces-titillium"
            className={titillium.className}
            dangerouslySetInnerHTML={{
                __html: `
                :root {
                    --font-family-titillium: 'Titillium Web', ${titillium.style.fontFamily}, Helvetica, Arial,
                        sans-serif;
                }`,
            }}
        />
    </Head>
);
